import i18next from 'i18next';

import { commonConfig, getFallbackTranslation } from './i18n';

export const createI18nMock = (customTranslations?: Record<string, string>) => {
  const i18n = i18next.createInstance();

  const config: any = {
    ...commonConfig,
    debug: false,
    lng: customTranslations ? 'custom' : 'cimode',
    parseMissingKeyHandler: getFallbackTranslation,
    resources: {
      custom: {
        translation: { ...customTranslations },
      },
    },
  };

  i18n.init(config);

  return i18n;
};
