//@ts-expect-error
import langs from 'json!langs';
import _ from 'lodash';
import i18next, { type InitOptions } from 'i18next';
import * as utils from '@wix/santa-editor-utils';
import * as abTranslate from 'ab-translate';
import experiments from 'experiment';

const abTranslations = abTranslate.default(
  experiments.getRunningExperiments(),
  langs,
);

export const formattedTranslation: { [key: string]: string } = {
  ...abTranslations,
  ..._.mapKeys(abTranslations, (value, key) => key.toLowerCase()),
};

export const getFallbackTranslation = (key: string) => `!${key}!`;

export const commonConfig = {
  keySeparator: false,
  react: {
    useSuspense: false,
  },
  parseMissingKeyHandler: getFallbackTranslation,
  interpolation: {
    prefix: '<%=',
    suffix: '%>',
    escapeValue: false,
  },
} as const;

export const FALLBACK_LANGUAGE = 'en';
export const NAMESPACE = 'translation';

const locale = utils.editorModel.languageCode || FALLBACK_LANGUAGE;

export const init18n = () => createI18n();

// const reuseI18n = () => { // studio has differnet interpolation config
//   i18next.addResources(locale, NAMESPACE, formattedTranslation);
//   return i18next;
// };

const createI18n = () => {
  const i18n = i18next.createInstance();

  const config: InitOptions = {
    ...commonConfig,
    lng: locale,
    fallbackLng: FALLBACK_LANGUAGE,
    resources: {
      [locale]: {
        [NAMESPACE]: formattedTranslation,
      },
    },
  };

  i18n.init(config);

  return i18n;
};
