import * as utils from '@wix/santa-editor-utils';

import type { EditorAPI } from '#packages/editorAPI';

const TRANSLATION_ERROR = {
  errorCode: 200,
  errorName: 'TRANSLATION_ERROR',
  desc: 'error translating text with parameters',
  severity: 'warning',
  params: {
    p1: 'key',
    p2: 'translated',
  },
};

const errors: AnyFixMe = [];
let error = function (...args: AnyFixMe[]) {
  errors.push(args);
};

const reportFunction = utils.url.isQA()
  ? // eslint-disable-next-line no-console
    (key: string) => console.warn(`missing key:${key}`)
  : (key: string) => error(TRANSLATION_ERROR, { key });

const reportedKeys: Record<string, boolean> = {};

export const reportMissingTranslationError = (key: string) => {
  if (!reportedKeys[key]) {
    reportedKeys[key] = true;
    reportFunction(key);
  }
};

export const initErrorHandlings = (editorAPI: EditorAPI) => {
  error = editorAPI.dsActions.bi.error;
  errors.forEach(function (args: AnyFixMe) {
    error.apply(null, args);
  });

  errors.length = 0;
};
