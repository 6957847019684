import * as utils from '@wix/santa-editor-utils';

import {
  init18n,
  getFallbackTranslation,
  FALLBACK_LANGUAGE,
  NAMESPACE,
} from './i18n';
import {
  initTranslationUsageReporting,
  reportTranslationUsage,
} from './reportTranslationKeyUsage/reportTranslationKeyUsage';
import {
  initErrorHandlings,
  reportMissingTranslationError,
} from './errorHandling';

import type { EditorAPI } from '#packages/editorAPI';

window.reverseTranslations = {};

export const i18n = init18n();

interface CustomInterpolationObject {
  prefix: string;
  suffix: string;
}

// TODO get rid of this map when all translations have standard placeholders
// Please DO NOT extend this object with new values
// Try to use standard placeholder format instead - <%= key %>
const CUSTOM_INTERPOLATION_MAP: Record<string, CustomInterpolationObject> = {
  mobile_hidden_items_section_title_currentpage: {
    suffix: '$$',
    prefix: '$$',
  },
  Show_Language_Popup_Success_Title: {
    prefix: '{{',
    suffix: '}}',
  },
  Show_Language_Popup_Success_Text: {
    prefix: '{{',
    suffix: '}}',
  },
  Hide_Language_Popup_Title: {
    prefix: '{{',
    suffix: '}}',
  },
  Hide_Language_Popup_Text: {
    prefix: '{{',
    suffix: '}}',
  },
  Notifications_AppWidget_ApplyDesignToLikeComponents_Link: {
    prefix: '{{',
    suffix: '}}',
  },
  Notifications_AppWidget_ApplyDesignToLikeComponents_Text: {
    prefix: '{{',
    suffix: '}}',
  },
  SiteLanguages_Panel_Show_Language_Tooltip: {
    prefix: '{{',
    suffix: '}}',
  },
  DELETE_TPA_INSTRUCTIONS_PANEL_title: {
    prefix: '{{',
    suffix: '}}',
  },
  delete_tpa_instructions_panel_subTitle: {
    prefix: '{{',
    suffix: '}}',
  },
  Multilingual_Mode_LangMenu_Activate_Tooltip: {
    prefix: '{',
    suffix: '}',
  },
  Contact_Form_Fields_Field_Type_Label: {
    prefix: '{',
    suffix: '}',
  },
  SAVE_PUBLISH_WHATS_NEXT_PERSONAL_SALE_TEXT_DYNAMIC: {
    prefix: '${',
    suffix: '}',
  },
  TOPBAR_UPGRADE_POPUP_PERSONAL_SALE_BUTTON_DYNAMIC: {
    prefix: '${',
    suffix: '}',
  },
  TOPBAR_UPGRADE_POPUP_PERSONAL_SALE_LABEL_DYNAMIC: {
    prefix: '${',
    suffix: '}',
  },
};

const composeWithCustomInterpolation = (
  key: string,
  values: Record<string, any> | string,
) => {
  const customInterpolation =
    CUSTOM_INTERPOLATION_MAP[key as keyof typeof CUSTOM_INTERPOLATION_MAP];

  if (customInterpolation) {
    const { prefix, suffix } = customInterpolation;

    return {
      ...(values as Record<string, any>),
      interpolation: {
        prefix,
        suffix,
      },
    };
  }

  return values;
};

//TODO: Get rid of this bullshit after we gonna understand where in codebase we really need to check lowercase key and change that
const getTranslated = (key: string, values: Record<string, any> | string) => {
  const keyLowerCase = key.toLowerCase();
  // TODO get rid of this when all translations have standard placeholders
  const valuesWithInterpolation = composeWithCustomInterpolation(key, values);

  const regularTranslated = i18n.t(key, valuesWithInterpolation);
  const lowerCaseTranslated = i18n.t(keyLowerCase, valuesWithInterpolation);

  if (regularTranslated === getFallbackTranslation(key)) {
    if (lowerCaseTranslated === getFallbackTranslation(keyLowerCase)) {
      return '';
    }

    return lowerCaseTranslated;
  }

  return regularTranslated;
};

export const translate = (
  key: string,
  values?: Record<string, any> | string,
) => {
  if (!key) {
    return '';
  }

  if (typeof key !== 'string') {
    // catch errors like `e.toLowerCase is not a function`
    // https://sentry-next.wixpress.com/organizations/santa-editor/issues/21630/
    throw new Error(
      `Could not use '${JSON.stringify(
        key,
      )}' as translation key. Translation key, should be string`,
    );
  }

  const translated = getTranslated(key, values);
  const fallbackTranslation = getFallbackTranslation(key);

  if (process.env.NODE_ENV === 'test' || utils.url.isQA()) {
    window.reverseTranslations[translated || fallbackTranslation] = key;
  }

  if (translated) {
    reportTranslationUsage(key);

    return translated;
  }

  reportMissingTranslationError(key);

  return fallbackTranslation;
};
export const t = translate;
export const addResources = (resources: AnyFixMe) => {
  i18n.addResources(i18n.language || FALLBACK_LANGUAGE, NAMESPACE, {
    ...resources,
  });
};

export const init = (editorAPI: EditorAPI) => {
  initTranslationUsageReporting(editorAPI);
  initErrorHandlings(editorAPI);
};

export const i18nNamespace = NAMESPACE;

export { getFallbackTranslation };

//@ts-expect-error
window.oldAddPanel = window.oldAddPanel || {};
//@ts-expect-error
window.oldAddPanel.translate = translate;
